const config = {
  STRIPE_KEY: process.env.STUDIO_STRIPE_KEY,
  apiGateway: {
    REGION: process.env.STUDIO_REGION,
    URL: process.env.STUDIO_API_URL_ACTUAL
  },
  gemsApiGateway: {
    REGION: process.env.STUDIO_REGION,
    URL: process.env.STUDIO_GEMS_API_URL
  },
  codexApiGateway: {
    REGION: process.env.STUDIO_REGION,
    URL: process.env.STUDIO_CODEX_API_URL
  },
  cognito: {
    REGION: process.env.STUDIO_REGION,
    USER_POOL_ID: process.env.STUDIO_USER_POOL_ID_ACTUAL,
    APP_CLIENT_ID: process.env.STUDIO_USER_POOL_CLIENT_ID_ACTUAL,
    IDENTITY_POOL_ID: process.env.STUDIO_IDENTITY_POOL_ID_ACTUAL
  }
};

export default config;
