import { createContext, useContext } from 'react';
import { Organization } from '../types';
import BillingStatus from './enums/billingStatus';

type Context = {
  isAuthenticated: boolean;
  hasOrganization: boolean;
  organization: Organization;
  setOrganization: (organization: Organization) => void;
  // eslint-disable-next-line no-unused-vars
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
};

export const AppContext = createContext<Context>({
  isAuthenticated: false,
  hasOrganization: false,
  setOrganization: () => {},
  organization: {
    name: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    billingStatus: BillingStatus.NONE,
    customerId: '',
    trialEnd: '',
    exemptions: []
  },
  login: async (email: string, password: string) => {},
  logout: async () => {}
});

export const useAppContext = () => useContext(AppContext);
