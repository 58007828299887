import React from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { hasValidSubscription, hasBillingProblem } from '../utils/billingUtils';

const billingIssueRoute = '/billing-issue';
const onboardingRoute = '/onboarding';

export default function AuthenticatedRoute({ roles, children, ...rest }: any) {
  const { isAuthenticated, hasOrganization, organization } = useAppContext();

  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const isBillingIssueOrOnboardingPage = [billingIssueRoute, onboardingRoute].includes(
    location.pathname
  );

  if (!isBillingIssueOrOnboardingPage) {
    if (hasBillingProblem(organization.billingStatus)) {
      return <Navigate to={billingIssueRoute} />;
    }

    const needsFurtherSetup = !hasOrganization || !hasValidSubscription(organization.billingStatus);
    if (needsFurtherSetup) {
      return <Navigate to={onboardingRoute} />;
    }
  }

  return <>{children}</>;
}
