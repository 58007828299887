import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GoogleFontLoader from 'global/components/GoogleFontLoader';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import loadable from '@loadable/component';
import theme from 'global/theme';
import FONTS from 'global/enums/fonts';
import { AppContext } from 'global/AppContext';
import { Organization } from './types';
import BillingStatus from 'global/enums/billingStatus';
import AuthenticatedRoute from 'global/components/AuthenticatedRoute';
import PageLoader from 'global/components/PageLoader';
import Fallback from 'global/components/Fallback';
import { inspect } from '@xstate/inspect';
import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import config from './config';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/MultiRangeSlider.css';
import './assets/css/MultiRangeSliderBlack.css';
import 'src/assets/css/PrettyCheckbox.css';
import GlobalStyles from './GlobalStyles';

const SignupView = loadable(() => import('./features/auth/views/SignupView'));
const LoginView = loadable(() => import('./features/auth/views/LoginView'));
const ForgotView = loadable(() => import('./features/auth/views/ForgotView'));
const NotFoundView = loadable(() => import('./features/auth/views/NotFoundView'));
const OnboardingView = loadable(() => import('./features/onboarding/views/OnboardingView'));
const OnboardingStatusView = loadable(
  () => import('./features/onboarding/views/OnboardingStatusView')
);
const CodicesView = loadable(() => import('src/features/codices/views/CodicesView'), {
  fallback: <Fallback />
});
const CodexView = loadable(() => import('src/features/codices/views/CodexView'), {
  fallback: <Fallback />
});
const DocumentView = loadable(() => import('src/features/codices/views/DocumentView'), {
  fallback: <Fallback />
});
const OrganizationView = loadable(() => import('src/features/organization/OrganizationView'), {
  fallback: <Fallback />
});
const BillingView = loadable(() => import('./billing/BillingView'), {
  fallback: <Fallback />
});
const BillingIssueView = loadable(() => import('./billing/BillingIssueView'), {
  fallback: <Fallback />
});
const GemsView = loadable(() => import('./features/gems/views/GemsView'), {
  fallback: <Fallback />
});
const ProjectView = loadable(() => import('./features/projects/views/ProjectView'), {
  fallback: <Fallback />
});
const EditorView = loadable(() => import('./features/editor/views/EditorView'), {
  fallback: <Fallback />
});
const UsersView = loadable(() => import('./features/users/views/UsersView'), {
  fallback: <Fallback />
});
const CustomizationView = loadable(() => import('./features/organization/CustomizationView'), {
  fallback: <Fallback />
});

if (process.env.USE_INSPECTOR === 'true') {
  inspect({
    iframe: false // open in new window
  });
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'v1',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'gems',
        endpoint: config.gemsApiGateway.URL,
        region: config.gemsApiGateway.REGION
      },
      {
        name: 'codex',
        endpoint: config.codexApiGateway.URL,
        region: config.codexApiGateway.REGION
      }
    ]
  }
});

const App = () => {
  const emptyOrganization = {
    name: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    billingStatus: BillingStatus.NONE,
    trialEnd: ''
  };

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [hasOrganization, userHasOrganization] = useState(false);
  const [organization, setOrganization] = useState<Organization>(emptyOrganization);

  const loadOrganization = async () => {
    try {
      const apiOrganization = await API.get('v1', '/organizations', {});
      if (apiOrganization) {
        setOrganization(apiOrganization);
        userHasOrganization(true);
      }
    } catch (e) {
      // user does not have a users
    }
  };

  const logout = async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    setOrganization(emptyOrganization);
  };

  const login = async (email: string, password: string) => {
    await Auth.signIn(email, password);
    await loadOrganization();
    userHasAuthenticated(true);
  };

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      await loadOrganization();
    } catch (e) {
      // no user
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  const Logout = () => {
    useEffect(() => {
      if (isAuthenticated) {
        logout();
      }
    }, []);
    if (isAuthenticated) {
      return null;
    }
    return <Navigate to="/" />;
  };

  return (
    <>
      <GlobalStyles />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <ThemeProvider theme={theme}>
        <GoogleFontLoader
          fonts={[
            {
              font: FONTS.HEADING,
              weights: [300, 500, 600, 700]
            },
            {
              font: FONTS.BODY,
              weights: [100, 400, 700]
            }
          ]}
        />
        <AppContext.Provider
          value={{
            isAuthenticated,
            hasOrganization,
            organization,
            setOrganization,
            login,
            logout
          }}
        >
          {isAuthenticating ? (
            <PageLoader />
          ) : (
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/gems" />} />
                <Route path="/login" element={<LoginView />} />
                <Route path="/signup" element={<SignupView />} />
                <Route path="/forgot-password" element={<ForgotView />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/onboarding-status" element={<OnboardingStatusView />} />
                {/* Authenticated */}
                <Route
                  path="/users"
                  element={
                    <AuthenticatedRoute>
                      <UsersView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/onboarding"
                  element={
                    <AuthenticatedRoute>
                      <OnboardingView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/organization"
                  element={
                    <AuthenticatedRoute>
                      <OrganizationView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/billing"
                  element={
                    <AuthenticatedRoute>
                      <BillingView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/billing-issue"
                  element={
                    <AuthenticatedRoute>
                      <BillingIssueView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/gems"
                  element={
                    <AuthenticatedRoute>
                      <GemsView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/codices"
                  element={
                    <AuthenticatedRoute>
                      <CodicesView />
                    </AuthenticatedRoute>
                  }
                />
                <Route path="/codices/:codexId" element={<CodexView />} />
                <Route path="/codices/:codexId/:documentId" element={<DocumentView />} />
                <Route
                  path="/customization"
                  element={
                    <AuthenticatedRoute>
                      <CustomizationView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/gems/interactive/:projectId"
                  element={
                    <AuthenticatedRoute>
                      <ProjectView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/gems/interactive/:projectId/:sceneId"
                  element={
                    <AuthenticatedRoute>
                      <EditorView />
                    </AuthenticatedRoute>
                  }
                />
                <Route path="*" element={<NotFoundView />} />
              </Routes>
            </Router>
          )}
        </AppContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
