const theme = {
  transitions: {
    default: 'all 0.2s ease-in-out'
  },
  colors: {
    white: '#FFFFFF',
    light: '#f8f7f4',
    border: '#dce0e7',
    dark: '#282c53',
    primary: '#4450CE',
    secondary: '#d16500',
    danger: '#f2545b',
    info: '#73C9E5',
    warning: '#CF8850',
    success: '#8DC192',
    grey: '#D9D9D9',
    alerts: {
      success: {
        text: '#155724',
        background: '#d4edda',
        border: '#c3e6cb'
      },
      danger: {
        text: '#222',
        background: '#f8d7da',
        border: '#f5c6cb'
      },
      dark: {
        text: '#282c53',
        background: '#d6d8d9',
        border: '#c6c8ca'
      }
    }
  },
  spacing: {
    xxl: '64px',
    xl: '32px',
    lg: '24px',
    default: '16px',
    md: '12px',
    sm: '8px',
    xs: '4px'
  },
  breakpoints: {
    tablet: {
      large: '1024px',
      small: '768px'
    },
    mobile: {
      large: '425px',
      medium: '375px',
      small: '320px'
    }
  },
  settings: {
    panelsEnabled: true
  },
  player: {
    spacing: {
      xl: '64px',
      lg: '32px',
      md: '16px',
      sm: '8px',
      xs: '4px'
    }
  }
};

export default theme;
