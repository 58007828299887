// @ts-nocheck
import React, { useRef, useState } from 'react';
import { NavLink, Link, LinkProps } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import FontAwesomeIcon from 'global/components/FontAwesomeIcon';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppContext } from '../AppContext';
import { hasFreeTrial } from '../utils/billingUtils';
import useClickOutside from '../hooks/useClickOutside';
const LogoDark = require('assets/img/Logo-Dark.svg');

const Nav = styled.nav`
  height: 45px;
  width: 100%;
  background: ${(props) => props.theme.colors.dark};
  display: flex;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing.lg};
  position: relative;
  justify-content: space-between;
`;

const NavItems = styled.ul<{ center?: boolean }>`
  display: flex;
  list-style: none;
  padding: 0;
  ${({ center }) => {
    if (center) {
      return `
        position: absolute;
        width: 100%;
        left: 0;
        justify-content: center;
      `;
    }
  }}
`;

const NavItem = styled.li``;

const NavItemLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes underlineIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  color: #ffffff9c;
  text-decoration: none;
  padding: 0;
  border-radius: 4px;
  position: relative;
  &.active {
    color: #fff;
  }
  margin-right: 18px;
  i {
    font-size: 0.8rem;
    margin-right: ${(props) => props.theme.spacing.xs};
  }
`;

const SubMenu = styled.div`
  position: relative;
`;

const Logo = styled.img`
  max-height: 24px;
`;

const ProvideFeedbackLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};
  font-size: 0.875em;
  font-weight: 500;
  margin-right: 16px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet.small}) {
    display: none;
  }
`;

const OrganizationMenuIconContainer = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const OrganizationMenuIconBackground = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 1;
`;

const OrganizationIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.5em;
  z-index: 2;
`;

const Container = styled(motion.div)`
  background-color: #fff;
  position: absolute;
  right: 0;
  width: 200px;
  border-radius: 4px;
  margin-top: ${({ theme }) => theme.spacing.sm};
  box-shadow: 0px 0px 6px 0px rgba(24, 22, 56, 0.5);
  z-index: 10;
  overflow: hidden;
`;

const Items = styled.ul`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ItemContainer = styled.li`
  width: 100%;
  padding: 4px;
`;

const Item = styled(Link)<LinkProps>`
  display: block;
  padding: 4px 8px;
  border-radius: 4px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 0.875em;
  transition: ${({ theme }) => theme.transitions.default};
  :hover {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

const FreeTrialText = styled.p`
  padding: 4px 8px;
  font-size: 0.75em;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
`;

const ItemIcon = styled(FontAwesomeIcon)<{ icon: any }>`
  margin-right: 0.5rem;
`;

const SUPPORT_LINK = 'https://www.riframe.io/support';

export default function NavBar() {
  const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] = useState(false);
  const { hasOrganization, organization } = useAppContext();
  const organizationMenu = useRef(null);
  let freeTrialText = '';
  if (hasFreeTrial(organization.billingStatus)) {
    const now = new Date();
    const distance = formatDistance(now, new Date(organization.trialEnd));
    freeTrialText = `Your free trial ends in ${distance}.`;
  }

  useClickOutside(organizationMenu, () => setIsOrganizationMenuOpen(false));

  return (
    <Nav>
      <NavItems>
        <NavItem>
          <Link to="/gems">
            <Logo src={LogoDark} alt="riframe logo" />
          </Link>
        </NavItem>
      </NavItems>
      <NavItems center>
        <NavItem>
          <NavItemLink to="/gems">
            <FontAwesomeIcon icon="gem" />
            Gems
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLink to="/codices">
            <FontAwesomeIcon icon="building-columns" />
            Codices
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLink to="/users">
            <FontAwesomeIcon icon="users" />
            Users
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLink to="/customization">
            <FontAwesomeIcon icon="gear" />
            Customization
          </NavItemLink>
        </NavItem>
      </NavItems>
      <NavItems>
        <NavItem>
          <ProvideFeedbackLink href={SUPPORT_LINK} target="_blank">
            Need Support?
          </ProvideFeedbackLink>
        </NavItem>
        <NavItem>
          <SubMenu ref={organizationMenu}>
            <OrganizationMenuIconContainer
              onClick={() => setIsOrganizationMenuOpen(!isOrganizationMenuOpen)}
            >
              <OrganizationMenuIconBackground />
              <OrganizationIcon icon="user-circle" />
            </OrganizationMenuIconContainer>
            <AnimatePresence>
              {isOrganizationMenuOpen && (
                <Container
                  initial={{
                    opacity: 0,
                    y: 50
                  }}
                  animate={{
                    opacity: 1,
                    y: 0
                  }}
                  exit={{
                    opacity: 0,
                    y: 50
                  }}
                  transition={{
                    duration: 0.25
                  }}
                >
                  <Items>
                    {hasOrganization && (
                      <>
                        {freeTrialText && (
                          <ItemContainer>
                            <FreeTrialText>{freeTrialText}</FreeTrialText>
                          </ItemContainer>
                        )}
                        <ItemContainer>
                          <Item to="/organization">
                            <ItemIcon icon="user" />
                            Your Organization
                          </Item>
                        </ItemContainer>
                        <ItemContainer>
                          <Item to="/billing">
                            <ItemIcon icon="money-bill" />
                            Billing
                          </Item>
                        </ItemContainer>
                      </>
                    )}
                    <ItemContainer>
                      <Item to="/logout">
                        <ItemIcon icon="door-open" />
                        Logout
                      </Item>
                    </ItemContainer>
                  </Items>
                </Container>
              )}
            </AnimatePresence>
          </SubMenu>
        </NavItem>
      </NavItems>
    </Nav>
  );
}
